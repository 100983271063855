import { createClient } from '@reachfive/identity-core';
import { AUTH_DOMAIN, CHINA_VERSION, REACT_APP_BBE_CONFIG } from '../globals';

const client =
  !CHINA_VERSION &&
  createClient({
    domain: AUTH_DOMAIN,
    clientId: REACT_APP_BBE_CONFIG.reach5,
  });

// expose client on window to allow us to stub the client methods for cypress testing
if (window.Cypress) {
  window.client = client;
}

export default client;
