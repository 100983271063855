import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  phone: '',
  hotels: {},
  hotelsRateContent: {},
  bookingRateContent: {},
  trains: {},
};

export const belmondSlice = createSlice({
  initialState,
  name: 'belmondSlice',
  reducers: {
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setHotels: (state, action) => {
      state.hotels = action.payload.reduce((acc, hotel) => {
        acc[hotel.productCode] = hotel;
        return acc;
      }, {});
    },
    extendHotel: (state, action) => {
      state.hotels[action.payload.productCode] = {
        ...state.hotels[action.payload.productCode],
        ...action.payload,
      };
    },
    setTrains: (state, action) => {
      state.trains = action.payload.reduce((acc, train) => {
        acc[train.productCode] = train;
        return acc;
      }, {});
    },
    setHotelsRateContent: (
      state,
      { payload: { productCode, rateCode, rateContent, bookingId } }
    ) => {
      const targetState = bookingId
        ? state.bookingRateContent
        : state.hotelsRateContent;

      const targetProperty = bookingId || rateCode;

      targetState[productCode] = {
        ...targetState[productCode],
        [targetProperty]: rateContent,
      };
    },
  },
});

export const fetchHotels = (axios) => async (dispatch) => {
  try {
    let { data } = await axios.get('/properties');
    // dont bother setting if we've gone outside of the base page
    if (window.location.pathname !== '/') return;
    dispatch(setHotels(data));
  } catch (e) {
    console.error(e);
  }
};

export const fetchHotel =
  (productCode, axios, countryCode) => async (dispatch) => {
    try {
      const res = await axios.get(`/hotel/${productCode}`, {
        params: { country_code: countryCode },
      });

      dispatch(extendHotel(res.data));
      return res.data;
    } catch (e) {
      console.error(e);
    }
  };

export const fetchHotelsRateContent =
  (params, moment, axios, bookingId) => async (dispatch) => {
    const { productCode, rateCode } = params;
    localStorage.setItem(`fetching-${productCode}-${rateCode}`, true);

    try {
      const { data } = await axios.get('/rate-content', {
        params,
      });

      if (!data) return;

      let payload = {
        productCode,
        rateCode,
        bookingId,
        rateContent: {
          ...data,
          lastFetched: moment().toISOString(),
          lastFetchedParams: params,
          agentId: params.agentId,
        },
      };

      dispatch(setHotelsRateContent(payload));
    } catch (e) {
      console.error(e);
    } finally {
      localStorage.removeItem(`fetching-${productCode}-${rateCode}`);
    }
  };

export const {
  setPhone,
  setHotels,
  extendHotel,
  setTrains,
  setHotelsRateContent,
} = belmondSlice.actions;

export default belmondSlice.reducer;
