import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/Cross.svg';
import { gtmRemoveFromCart } from '../../gtm/events';
import navigateAfterRemovingAllBookings from '../../helpers/navigate-after-removing-all-bookings';
import useMessage from '../../hooks/useMessage/useMessage';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import useTotalPrice from '../../hooks/useTotalPrice/useTotalPrice';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import {
  bookingsCartStatuses,
  removeAgentProfile,
  removeBooking,
  setCurrentBooking,
} from '../../redux/slices/bookingsSlice/bookingsSlice';
import AccesibilityDiv from '../AccesibilityElement/AccesibilityElement';
import LoadingButton from '../LoadingButton/LoadingButton';
import styles from './BookingsCart.module.css';
import BookingsCartItem from './BookingsCartItem/BookingsCartItem';
import NextRoomPlaceholder from './NextRoomPlaceholder/NextRoomPlaceholder';

const { EDIT_BOOKING, EDIT_ADDONS, STAND_BY, CHECK_OUT, ADD_NEW_BOOKING } =
  bookingsCartStatuses;

const BookingsCart = ({
  status,
  bookings,
  currentBookingId,
  pendingAddonsActions,
  error,
  addonsLoading,
  selectRoomForm,
  onClose,
  onRemoveAddon,
  onConfirmChangesAddons,
  onDiscardChangesAddons,
  highlightedRoomIndex,
  startDate,
  endDate,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLargeDesktop } = useScreenDetector();
  const [Message, showMessage, closeMessage] = useMessage();
  const hotels = useSelector((state) => state.belmond.hotels);

  // use the given start or end date, or get it from the form if it exists
  const { watch = () => {} } = selectRoomForm || {};
  const formStartDate = watch('startDate');
  const formEndDate = watch('endDate');

  const { totalPrice, differentCurrencies } = useTotalPrice(
    bookings,
    pendingAddonsActions
  );

  const numRooms = selectRoomForm?.watch('guestsPerRoom').length;

  const extendedVersion = useSelector(
    (state) => state.appSettings.extendedVersion
  );
  const currency = useSelector((state) => state.appSettings.currencies.current);

  const showNextRoomPlaceholder =
    status === ADD_NEW_BOOKING &&
    !!bookings.length &&
    bookings.length < numRooms;

  const alternateCurrency = bookings.some(
    ({ productCode }) => hotels[productCode].currency !== currency
  );

  // currently disabled
  // const handleAddAnotherRoomBtnClick = () => {
  //   navigate(`/select-room?productCode=${bookings[0].productCode}`);
  // };

  const handleAddAnotherProductBtnClick = () => {
    navigate('/');
  };

  const handleBookingsCartItemEdit = (productCode, bookingId) => {
    dispatch(setCurrentBooking(bookingId));
    navigate(`/edit-room?productCode=${productCode}`, { replace: true });
  };

  const handleBookingsCartItemRemove = (bookingId) => {
    const booking = bookings.find(({ id }) => id === bookingId);
    gtmRemoveFromCart(booking, hotels[booking.productCode]);
    dispatch(removeBooking(bookingId));

    // if last booking is removed
    if (bookings.length === 1) {
      if (onClose) {
        onClose();
      }
      dispatch(removeAgentProfile());
      navigateAfterRemovingAllBookings(navigate, bookings);
    }
  };

  useEffect(() => {
    if (error) {
      showMessage(error, 'danger');
    } else {
      closeMessage();
    }
  }, [closeMessage, error, showMessage]);

  return (
    <>
      <div
        className={isLargeDesktop ? styles.BookingsCart__cartContainer : ''}
        data-testid="mainContainer"
      >
        <div className={styles.BookingsCart__headerContainer}>
          <h2 style={{ padding: 0 }}>{t('My Itinerary')}</h2>
          {!isLargeDesktop && (
            <AccesibilityDiv
              className={styles.BookingsCart__closeButton}
              onClick={onClose}
              tagName="div"
              ariaLabel="Exit BookingsCart mobile"
              role="button"
            >
              <CrossIcon />
            </AccesibilityDiv>
          )}
        </div>

        {bookings.map((booking, i) => {
          const defaultCollapsed =
            status === ADD_NEW_BOOKING ||
            (currentBookingId && currentBookingId !== booking.id) ||
            (!currentBookingId && i > 0);

          const showVerticalSeparator =
            status === EDIT_BOOKING &&
            currentBookingId === booking.id &&
            bookings.length > 1;

          return (
            <BookingsCartItem
              bookingsCartStatus={status}
              booking={booking}
              pendingAddonsActions={pendingAddonsActions}
              defaultCollapsed={defaultCollapsed}
              key={`bookings-cart-item-${booking.id}-${booking.productCode}`}
              showVerticalSeparator={showVerticalSeparator}
              collapsable={bookings.length > 1}
              title={`${t('Room')} ${i + 1} ${t('of')} ${bookings.length}`}
              onEdit={() =>
                handleBookingsCartItemEdit(booking.productCode, booking.id)
              }
              onRemove={() => handleBookingsCartItemRemove(booking.id)}
              onRemoveAddon={onRemoveAddon}
            />
          );
        })}

        {/*
          TODO: work this into the new room functionality
          this will have to trigger another inventory check
          so it's not as easy as selecting another room
          because we need to trigger availability checks with the calendar

         {status === CHECK_OUT && bookings[0].status === UNSUBMITTED && (
          <Button
            className="button w-100 transparent mb-3"
            onClick={handleAddAnotherRoomBtnClick}
            aria-label="Add another room"
          >
            Add Another Room
          </Button>
        )} */}

        {showNextRoomPlaceholder && (
          <NextRoomPlaceholder
            startDate={startDate || formStartDate}
            endDate={endDate || formEndDate}
            selectRoomForm={selectRoomForm}
            highlightedRoomIndex={highlightedRoomIndex}
          />
        )}

        {status === EDIT_ADDONS && (
          <>
            <LoadingButton
              loading={addonsLoading}
              type="button"
              ariaLabel="confirm addons"
              className="button w-100 mb-4"
              onClick={onConfirmChangesAddons}
            >
              {t('Confirm')}
            </LoadingButton>
            <Button
              type="button"
              aria-label="discard addons"
              className="button transparent w-100 mb-4"
              onClick={onDiscardChangesAddons}
            >
              {t('Discard')}
            </Button>
          </>
        )}

        {alternateCurrency && (
          <div className={styles.BookingsCart__alternateCurrency}>
            <div className={styles.BookingsCart__heading}>
              {t(
                'Alternate currency displays are for information purposes only.'
              )}
            </div>
          </div>
        )}
        <hr className="bbe-hr" />

        {differentCurrencies ? (
          <p className="mt-4">
            {t(
              'The cart contains different items with different currencies, therefore we will not be be able to display an accurate total.'
            )}
            <br />
            <br />
            {t('Please see the cart for the cost of your items.')}
          </p>
        ) : (
          <>
            <div className={styles.BookingsCart__totalPrice}>
              <span className="text-uppercase">{t('Total')}</span>
              <span>{totalPrice}</span>
            </div>
            <div className={styles.BookingsCart__tax}>
              ({t('tax included')})
            </div>
          </>
        )}
        {[STAND_BY, CHECK_OUT].includes(status) && extendedVersion && (
          <>
            <hr className="bbe-hr" />
            <div className={styles.BookingsCart__addProduct}>
              <button
                onClick={handleAddAnotherProductBtnClick}
                aria-label="add another belmond experience"
              >
                {t('Add Another Belmond Experience')}
              </button>
            </div>
          </>
        )}
      </div>
      <Message />
    </>
  );
};

BookingsCart.propTypes = {
  status: PropTypes.oneOf(Object.values(bookingsCartStatuses)),
  bookings: PropTypes.array,
  pendingAddonsActions: PropTypes.object,
  error: PropTypes.string,
  currentBookingId: PropTypes.string,
  addonsLoading: PropTypes.bool,
  selectRoomForm: PropTypes.object,
  onClose: PropTypes.func,
  onRemoveAddon: PropTypes.func,
  onConfirmChangesAddons: PropTypes.func,
  onDiscardChangesAddons: PropTypes.func,
  highlightedRoomIndex: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default BookingsCart;
