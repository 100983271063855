import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import DevLogin from './components/DevLogin/DevLogin';
import {
  currencies as hardcodedCurrencies,
  trains as hardcodedTrainsList,
} from './constants';
import { CHINA_VERSION, env } from './globals';
import ScriptLoader from './helpers/ScriptLoader/ScriptLoader';
import devLoginCheck from './helpers/devLoginCheck';
import getCountryCode from './helpers/get-country-code';
import useAxios from './hooks/useAxios/useAxios';
import useCheckReach5Session from './hooks/useCheckReach5Session/useCheckReach5Session';
import usePrevious from './hooks/usePrevious/usePrevious';
import {
  fetchExchanges,
  fetchLanguages,
  fetchStaticCnTranslations,
  setCountryCode,
  setCurrencies,
  setExtendedVersion,
  setloginEnabled,
  setNewsletterEnabled,
} from './redux/slices/appSettingsSlice/appSettingsSlice';
import {
  fetchHotel,
  fetchHotels,
  setPhone,
  setTrains,
} from './redux/slices/belmondSlice/belmondSlice';
import { fetchMedia } from './redux/slices/mediaSlice/mediaSlice';
import { checkActiveAuthSession } from './redux/slices/userSlice/userSlice';

const Root = () => {
  useCheckReach5Session();
  const axios = useAxios();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const countryCode = useSelector((state) => state.appSettings.countryCode);
  const bookings = useSelector((state) => state.bookings.list);

  const extendedVersionParam = searchParams.get('extended_version');
  const bypassParam = searchParams.get('bypass');

  const productCode = searchParams.get('productCode');
  const prevProductCode = usePrevious(productCode);

  const isDevLoginValid =
    JSON.parse(localStorage.getItem('validUser'))?.valid ||
    env === 'prod' ||
    bypassParam === 'diamond';

  const fetchCountryCode = useCallback(async () => {
    const result = await getCountryCode();
    dispatch(setCountryCode(result));
  }, [dispatch]);

  const fetchMetadata = useCallback(async () => {
    if (!countryCode) return;

    try {
      const res = await axios.get('/metadata', {
        params: { country_code: countryCode },
      });

      dispatch(setPhone(res.data.phone));
      dispatch(setloginEnabled(res.data.loginEnabled));
      dispatch(setNewsletterEnabled(res.data.newsletterEnabled));
    } catch (e) {
      console.error(e?.message || e);
    }
  }, [axios, dispatch, countryCode]);

  useEffect(() => {
    if (extendedVersionParam === '1') {
      dispatch(setExtendedVersion(true));
    } else if (extendedVersionParam === '0') {
      dispatch(setExtendedVersion(false));
    }
  }, [extendedVersionParam, dispatch]);

  useEffect(() => {
    if (bypassParam === 'diamond') {
      window.localStorage.setItem(
        'validUser',
        JSON.stringify({
          valid: true,
          timestamp: Date.now(),
        })
      );
    }
  }, [bypassParam]);

  useEffect(() => {
    const initCalls = async () => {
      try {
        await fetchCountryCode();
      } catch (e) {
        // ignore - falls back to GB inside getCountryCode
      } finally {
        const promises = [
          fetchMetadata(),
          dispatch(fetchExchanges(axios)),
          dispatch(fetchLanguages(axios)),
          dispatch(fetchHotels(axios)),
          dispatch(fetchMedia(axios)),
        ];

        if (CHINA_VERSION) {
          promises.push(dispatch(fetchStaticCnTranslations(axios)));
        }

        if (productCode) {
          promises.push(dispatch(fetchHotel(productCode, axios, countryCode)));
        }

        await Promise.all(promises);

        dispatch(setCurrencies(hardcodedCurrencies));
        dispatch(setTrains(hardcodedTrainsList));
        setLoading(false);
        devLoginCheck();
      }
    };

    if (scriptsLoaded) {
      initCalls();
    }
  }, [
    fetchMetadata,
    dispatch,
    scriptsLoaded,
    axios,
    fetchCountryCode,
    countryCode,
    productCode,
  ]);

  useEffect(() => {
    if (productCode && productCode !== prevProductCode) {
      dispatch(fetchHotel(productCode, axios, countryCode));
    }
  }, [productCode, prevProductCode, dispatch, axios, countryCode]);

  useEffect(() => {
    dispatch(checkActiveAuthSession());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [location]);

  useEffect(() => {
    const isEnterprisePage = location.pathname === '/';
    if (isEnterprisePage && bookings.length) {
      navigate('/checkout', { replace: true });
    }
  }, [location, navigate, bookings]);

  return (
    <>
      <ScriptLoader onScriptsLoad={() => setScriptsLoaded(true)} />
      {isDevLoginValid ? (
        <Outlet context={{ scriptsLoaded, metadataLoaded: !loading }} />
      ) : (
        <DevLogin />
      )}
    </>
  );
};

export default Root;
