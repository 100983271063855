import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/EditPen.svg';
import SelectRoomMobilePicker from '../../../../components/SelectRoomMobilePicker/SelectRoomMobilePicker';
import useLocalisedMoment from '../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

const SelectRoomMobileBar = ({ productCode, onPickerSubmit }) => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();
  const { watch } = useFormContext();

  const [mobilePickerOpen, setMobilePickerOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const guestsPerRoom = watch('guestsPerRoom');

  const numAdults = useMemo(
    () => guestsPerRoom.reduce((acc, room) => acc + room.numAdults, 0),
    [guestsPerRoom]
  );

  const numChildren = useMemo(
    () => guestsPerRoom.reduce((acc, room) => acc + room.numChildren, 0),
    [guestsPerRoom]
  );

  const handlePickerButtonClick = (section) => {
    setMobilePickerOpen(true);
    setActiveSection(section);
  };

  return (
    <div>
      <hr className="bbe-hr" />
      <div className="d-flex flex-column m-3">
        {mobilePickerOpen && (
          <SelectRoomMobilePicker
            productCode={productCode}
            onClose={() => {
              setMobilePickerOpen(false);
            }}
            onSubmit={() => {
              onPickerSubmit();
            }}
            activeSection={activeSection}
          />
        )}
        <Row className="w-100">
          <div className="d-flex">
            <Col xs="6 ">
              <button
                aria-label="Open guests picker"
                type="button"
                className=" d-flex flex-column"
                style={{ textAlign: 'left' }}
                onClick={() => handlePickerButtonClick('guests')}
              >
                <div className="bold text-uppercase">{t('Rooms & Guests')}</div>
                {guestsPerRoom.length}{' '}
                {guestsPerRoom.length > 1 ? t('rooms') : t('room')}, {numAdults}{' '}
                {numAdults > 1 ? t('adults') : t('adult')}
                {numChildren ? (
                  <div>
                    {numChildren} {numChildren > 1 ? t('children') : t('child')}
                  </div>
                ) : (
                  ''
                )}
              </button>
            </Col>
            <Col xs="5">
              <button
                aria-label="Open calendar picker"
                className="d-flex flex-column"
                type="button"
                onClick={() => handlePickerButtonClick('calendar')}
              >
                <div className="bold text-uppercase">{t('Dates')}</div>
                <div className="text-nowrap">
                  {moment(startDate).format('MMM D')} -{' '}
                  {moment(endDate).format('MMM D YYYY')}
                </div>
              </button>
            </Col>
            <Col
              xs="1"
              className="d-flex justify-content-end"
              style={{ marginLeft: 22 }}
            >
              <button
                aria-label="Open guests picker"
                className="d-flex flex-column"
                type="button"
                onClick={() => handlePickerButtonClick('guests')}
              >
                <EditIcon />
              </button>
            </Col>
          </div>
        </Row>
      </div>
      <hr className="bbe-hr" />
    </div>
  );
};

SelectRoomMobileBar.propTypes = {
  productCode: PropTypes.string,
  onPickerSubmit: PropTypes.func,
};

export default SelectRoomMobileBar;
