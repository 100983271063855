import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ErrorView from '../../views/ErrorView/ErrorView';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const errorHandler = (error, errorInfo) => {
      if (error?.message?.includes('ResizeObserver loop')) {
        return;
      }
      setHasError(true);
      console.log('error', error);
      console.log('errorInfo', errorInfo);

      // Send error to Datadog
      datadogRum.addError(error, {
        errorInfo,
      });
    };

    // Add event listener for uncaught errors
    window.addEventListener('error', errorHandler);

    // Add event listener for unhandled promise rejections
    window.addEventListener('unhandledrejection', errorHandler);

    return () => {
      // Remove event listeners on cleanup
      window.removeEventListener('error', errorHandler);
      window.removeEventListener('unhandledrejection', errorHandler);
    };
  }, []);

  if (hasError) {
    return <ErrorView />;
  }

  return children;
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
