import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './BelmondSingleTrain.module.css';

const BelmondSingleTrain = ({
  image,
  name,
  location,
  productCode,
  onClick,
}) => {
  const { t } = useTranslate();
  return (
    <div className={styles.BelmondSingleTrain__container}>
      <div className={styles.BelmondSingleTrain__imgContainer}>
        <img
          src={image}
          alt={name}
          className={styles.BelmondSingleTrain__img}
        />
      </div>
      <div className={styles.BelmondSingleTrain__info}>
        <div>
          <span className={styles.BelmondSingleTrain__name}>{name}</span>
          <span className={styles.BelmondSingleTrain__location}>
            {location}
          </span>
        </div>
      </div>
      <div className={styles.BelmondSingleTrain__buttonContainer}>
        <Button
          className={`button ${styles.BelmondSingleHotel_buttonLightGray}`}
          onClick={() => onClick(productCode)}
          aria-label={`Select ${name}`}
        >
          {t('Select')}
        </Button>
      </div>
    </div>
  );
};
BelmondSingleTrain.propTypes = {
  onClick: PropTypes.func,
  image: PropTypes.string,
  name: PropTypes.string,
  location: PropTypes.string,
  productCode: PropTypes.string,
};

export default BelmondSingleTrain;
