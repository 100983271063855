export const trains = [
  {
    name: 'Venice Simplon-Orient-Express',
    location: 'Europe',
    image:
      'https://img.belmond.com/image/upload/f_auto,t_320x222/photos/vso/vso-din-bar-car01.jpg',
    productCode: 'VSO',
  },
  {
    name: 'Royal Scotsman',
    location: 'Scotland, UK',
    image:
      'https://img.belmond.com/image/upload/f_auto,t_320x222/photos/trs/trs-ext11.jpg',
    productCode: 'RS',
  },
  {
    name: 'British Pullman',
    location: 'England, UK',
    image:
      'https://img.belmond.com/image/upload/f_auto,t_320x222/photos/pul/pul-ext02.jpg',
    productCode: 'BP',
  },
];

export const currencies = {
  AED: 'United Arab Emirates Dirham',
  ALL: 'Albanian Lek',
  ARS: 'Argentine Peso',
  AUD: 'Australian Dollar',
  AWG: 'Aruban Florin',
  AZN: 'Azerbaijani Manat',
  BAM: 'Bosnia-Herzegovina Convertible Mark',
  BBD: 'Barbadian Dollar',
  BDT: 'Bangladeshi Taka',
  BGN: 'Bulgarian Lev',
  BHD: 'Bahraini Dinar',
  BMD: 'Bermudan Dollar',
  BND: 'Brunei Dollar',
  BOB: 'Bolivian Boliviano',
  BRL: 'Brazilian Real',
  BWP: 'Botswanan Pula',
  CAD: 'Canadian Dollar',
  CHF: 'Swiss Franc',
  CLP: 'Chilean Peso',
  CNY: 'Chinese Yuan',
  COP: 'Colombian Peso',
  CZK: 'Czech Republic Koruna',
  DKK: 'Danish Krone',
  DOP: 'Dominican Peso',
  DZD: 'Algerian Dinar',
  EGP: 'Egyptian Pound',
  EUR: 'Euro',
  FJD: 'Fijian Dollar',
  GBP: 'British Pound Sterling',
  HKD: 'Hong Kong Dollar',
  HRK: 'Croatian Kuna',
  IDR: 'Indonesian Rupiah',
  ILS: 'Israeli New Sheqel',
  INR: 'Indian Rupee',
  ISK: 'Icelandic Króna',
  JOD: 'Jordanian Dinar',
  JPY: 'Japanese Yen',
  KES: 'Kenyan Shilling',
  KHR: 'Cambodian Riel',
  KRW: 'South Korean Won',
  KWD: 'Kuwaiti Dinar',
  KZT: 'Kazakhstani Tenge',
  LAK: 'Laotian Kip',
  LBP: 'Lebanese Pound',
  LKR: 'Sri Lankan Rupee',
  LYD: 'Libyan Dinar',
  MAD: 'Moroccan Dirham',
  MDL: 'Moldovan Leu',
  MGA: 'Malagasy Ariary',
  MKD: 'Macedonian Denar',
  MMK: 'Myanma Kyat',
  MNT: 'Mongolian Tugrik',
  MOP: 'Macanese Pataca',
  MUR: 'Mauritian Rupee',
  MVR: 'Maldivian Rufiyaa',
  MXN: 'Mexican Peso',
  MYR: 'Malaysian Ringgit',
  NGN: 'Nigerian Naira',
  NOK: 'Norwegian Krone',
  NZD: 'New Zealand Dollar',
  OMR: 'Omani Rial',
  PAB: 'Panamanian Balboa',
  PEN: 'Peruvian Nuevo Sol',
  PHP: 'Philippine Peso',
  PKR: 'Pakistani Rupee',
  PLN: 'Polish Zloty',
  QAR: 'Qatari Rial',
  RON: 'Romanian Leu',
  RSD: 'Serbian Dinar',
  RUB: 'Russian Ruble',
  SAR: 'Saudi Riyal',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  SZL: 'Swazi Lilangeni',
  THB: 'Thai Baht',
  TND: 'Tunisian Dinar',
  TRY: 'Turkish Lira',
  TTD: 'Trinidad and Tobago Dollar',
  TWD: 'New Taiwan Dollar',
  UAH: 'Ukrainian Hryvnia',
  USD: 'United States Dollar',
  UYU: 'Uruguayan Peso',
  VND: 'Vietnamese Dong',
  ZAR: 'South African Rand',
};
