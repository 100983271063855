import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import { CHINA_VERSION } from '../../globals';
import { setCurrentCurrency } from '../../redux/slices/appSettingsSlice/appSettingsSlice';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

const SingleHotelViewWrapper = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { scriptsLoaded, metadataLoaded } = useOutletContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const hotels = useSelector((state) => state.belmond.hotels);

  const bookingProductCode = useSelector((state) =>
    state.bookings.list.length ? state.bookings.list[0].productCode : null
  );
  const currencySelectedByUser = useSelector(
    (state) => state.appSettings.currencies.selectedByUser
  );

  const productCode = useRef(
    searchParams.get('productCode') || bookingProductCode
  );

  const hotel =
    useSelector((state) => state.belmond.hotels[productCode.current]) || {};

  useEffect(() => {
    if (!currencySelectedByUser) {
      let currencyToUse = CHINA_VERSION ? 'CNY' : hotel?.currency || 'GBP';
      dispatch(setCurrentCurrency(currencyToUse));
    }
  }, [currencySelectedByUser, dispatch, hotel?.currency]);

  const outletReady = scriptsLoaded && metadataLoaded;

  useEffect(() => {
    const checkMaintenance = async () => {
      const { maintenance } = hotels[productCode.current] || {};
      if (!maintenance) return;

      if (pathname !== `/maintenance/${productCode.current}`) {
        navigate(`/maintenance?productCode=${productCode.current}`);
      }
    };

    checkMaintenance();
  }, [navigate, pathname, hotels, productCode]);

  useEffect(() => {
    if (!productCode.current) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div data-testid="single-hotel-view">
      <Header
        phone={hotel?.phone}
        hotelCurrency={hotel?.currency}
        productCode={productCode.current}
      />

      {outletReady && <Outlet context={{ hotel }} />}
      {!outletReady && <ViewLoader />}

      <Footer
        loading={!outletReady}
        instagramLink={hotel?.metadata?.instagramLink}
        facebookLink={hotel?.metadata?.facebookLink}
        address={`${hotel?.address?.address}, ${hotel?.address?.city}, ${hotel?.address?.postalCode} `}
        phone={hotel?.phone}
        productCode={productCode.current}
        contactUsLink={hotel?.metadata?.contactUsLink}
        findUsLink={hotel?.metadata?.findUsLink}
      />
    </div>
  );
};

export default SingleHotelViewWrapper;
