import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Button, Col, Row } from 'reactstrap';
import { ReactComponent as GreenCheckIcon } from '../../../../assets/images/icons/greenCheck.svg';
import BookingButton from '../../../../components/BookingButton/BookingButton';
import usePrintPrice from '../../../../hooks/usePrintPrice/usePrintPrice';
import useRateContent from '../../../../hooks/useRateContent/useRateContent';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './RoomRate.module.css';
import { RoomRateDetails } from './components';

const RoomRate = ({
  rate,
  selectRoomForm,
  productCode,
  roomTypeCode,
  roomTypeTitle,
  numBookingNights,
  onRateDetailsBtnClick,
  onBookNowBtnClick,
  renderSecondaryButton,
}) => {
  const { t } = useTranslate();
  const {
    totalAmountBeforeTax,
    code,
    name,
    price,
    shortDescription,
    currencyCode,
    serviceCharge,
  } = rate;

  const { printPrice } = usePrintPrice(productCode);
  const formValues = selectRoomForm.watch();

  const { rateContent, loading } = useRateContent(
    code,
    productCode,
    formValues
  );

  const numRooms = selectRoomForm?.watch('guestsPerRoom')?.length || 1;
  const agentCrmId = selectRoomForm?.watch('agentCrmId');
  const agentId = selectRoomForm?.watch('agentId');

  const {
    price: ratePrice,
    isExlusiveTaxesAndFees,
    isIncludingServiceCharge,
  } = printPrice(
    {
      value: price,
      valueExcludingTaxesAndFees: totalAmountBeforeTax,
      valueIncludingServiceCharge: totalAmountBeforeTax + serviceCharge,
      productCode,
      baseCurrency: currencyCode,
    },
    true
  );

  let priceTypeMessage = '';

  switch (true) {
    case isIncludingServiceCharge:
      priceTypeMessage = [
        `${t('Including')} ${t('Service Charge')}`,
        `${t('Excluding')}  ${t('Taxes')}`,
      ];
      break;
    case isExlusiveTaxesAndFees:
      priceTypeMessage = [`${t('Excluding')} ${t('Taxes and Fees')}`];
      break;
    default:
      priceTypeMessage = [`${t('Including')} ${t('Taxes and Fees')}`];
      break;
  }

  return (
    <div className={styles.RoomRate__container}>
      {agentId && agentCrmId && rateContent?.commissionablePercentage > 0 && (
        <div className={styles.RoomRate__commissionContainer}>
          <div className={styles.RoomRate__icon}>
            <GreenCheckIcon />
          </div>
          <div>
            <div className={styles.RoomRate__description}>
              <span className="bold">{t('Offer applied')}: </span>
              {`${Math.round(rateContent?.commissionablePercentage)}% ${t(
                'commission per night'
              )}`}
            </div>
          </div>
        </div>
      )}
      <Row className="mt-2">
        <Col xs="6" className={styles.RoomRate__roomRate}>
          <div className="fs-5 fw-600">{name}</div>
          {loading ? (
            <div>
              <Skeleton className="my-3" height={25} />
              <Skeleton className="mb-3" height={50} />
            </div>
          ) : (
            <RoomRateDetails
              name={name}
              rateContent={rateContent}
              shortDescription={shortDescription}
            />
          )}
          <div>
            <Button
              className="hoverEffectButton text-nowrap"
              onClick={onRateDetailsBtnClick}
              aria-label={`Open modal for ${name}`}
              id={`${name}-${code}`}
            >
              {t('Rate Details')}
            </Button>
          </div>
        </Col>
        <Col xs="6" className={styles.RoomRate__roomPrice}>
          <div className={styles.RoomRate__roomPriceContainer}>
            <div className="mb-1">
              {t('Total for')} {numBookingNights}{' '}
              {numBookingNights > 1 ? t('nights') : t('night')}
            </div>
            <div className="d-flex align-items-end flex-column">
              {priceTypeMessage.map((m) => (
                <div key={m}>{m}</div>
              ))}
            </div>
            <div
              className={styles.RoomRate__price}
              data-testid="RoomRate--price"
            >
              {ratePrice}
            </div>
          </div>
        </Col>
      </Row>

      <div className={styles.RoomRate__buttons}>
        <div>{renderSecondaryButton}</div>
        <div>
          {loading ? (
            <Skeleton width={160} height={48} className="py-1" />
          ) : (
            <BookingButton
              rateCode={code}
              rateName={name}
              roomTypeTitle={roomTypeTitle}
              onBook={onBookNowBtnClick}
              roomTypeCode={roomTypeCode}
              numRooms={numRooms}
              gtmView="Regular"
            />
          )}
        </div>
      </div>
    </div>
  );
};

RoomRate.propTypes = {
  rate: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    shortDescription: PropTypes.string,
    longDescription: PropTypes.string,
    totalAmountBeforeTax: PropTypes.number,
    serviceCharge: PropTypes.number.isRequired,
    currencyCode: PropTypes.string.isRequired,
  }).isRequired,

  selectRoomForm: PropTypes.object.isRequired,
  productCode: PropTypes.string.isRequired,
  roomTypeCode: PropTypes.string.isRequired,
  numBookingNights: PropTypes.number.isRequired,
  onRateDetailsBtnClick: PropTypes.func.isRequired,
  onBookNowBtnClick: PropTypes.func.isRequired,
  renderSecondaryButton: PropTypes.node,
  roomTypeTitle: PropTypes.string.isRequired,
};

export default RoomRate;
