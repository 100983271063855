import axios from 'axios';
import axiosRetry, {
  exponentialDelay,
  isNetworkOrIdempotentRequestError,
} from 'axios-retry';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_DOMAIN } from '../../globals';

const maintenanceURLs = [
  '/calendar-availability',
  '/room-availability',
  '/check-room-availability',
  '/create-bookings',
  '/edit-booking',
  '/cancel-booking',
  '/request-booking-details-link',
];

const useAxios = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentLanguage = useSelector(
    (state) => state.appSettings.languages.current
  );

  const axiosWithRetry = useMemo(() => {
    const axiosInstance = axios.create({
      baseURL: API_DOMAIN,
      params: { lang: currentLanguage },
    });

    axiosRetry(axiosInstance, {
      retries: 5,
      retryDelay: (retryCount) => exponentialDelay(retryCount),
      retryCondition: (error) => {
        // Only retry GET requests
        // handle 503 errors seperately
        return (
          error.config.method === 'get' &&
          error.response?.status !== 503 &&
          (isNetworkOrIdempotentRequestError(error) ||
            error.response?.status >= 500)
        );
      },
    });

    // HANDLE 503 MAINTENANCE
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const {
          response: { status } = {},
          config: { method, params: { productCode } = {} } = {},
        } = error;

        const singlularProductCode =
          productCode && !productCode.includes(',') ? productCode : null;

        const isMaintenancePage = pathname === '/maintenance';

        // if already at page, do nothing
        if (isMaintenancePage) return;

        if (
          method === 'get' &&
          status === 503 &&
          singlularProductCode &&
          !maintenanceURLs.includes(pathname)
        ) {
          navigate(`/maintenance?${productCode}`);
        }
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }, [currentLanguage, navigate, pathname]);

  return axiosWithRetry;
};

export default useAxios;
