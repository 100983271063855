import React from 'react';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './RoomsNotAvailableMessage.module.css';

const RoomsNotAvailableMessage = ({ rateCodeApplied }) => {
  const { t } = useTranslate();
  return (
    <div
      className={styles.RoomsNotAvailableMessage__container}
      aria-label="There are no available rooms"
    >
      <div data-testid="message-for-unavailable-rooms">
        {rateCodeApplied
          ? t('This offer is not available for your selected dates.')
          : t(
              'Dates are unavailable but we may be able to accommodate you.'
            )}{' '}
        {t('Please, contact the hotel directly.')}
      </div>
    </div>
  );
};

export default RoomsNotAvailableMessage;
